<template>
  <b-icon :icon="svg" :variant="variant" font-scale="1.2"></b-icon>
</template>
<script>
export default {
  name: 'base-svg',
  props: {
    svg: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
};
</script>
