import groupBy from 'lodash.groupby';

export default {
  allApplications: state => state.applications,
  applications: state => type => (type ? groupBy(state.applications, 'applicationType')[type] : state.applications),
  application: state => appId => state.applications.find(({ id }) => id === appId),
  activeApplication: state => status => state.applications.find(({ active }) => active === status),
  profile: state => state.profile || { user: {}, customer: {} },
  loggedIn: state => state.loggedIn,
  organizationCustomers: state => state.organizationCustomers,
  pushSettings: state => state.pushSettings || {},
};
