import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App';

// router setup
import router from './routes/router';
import store from './store';
import messageService from '@/localization/messages';
import VueI18n from 'vue-i18n';
import '@/assets/sass/index.scss';

// plugin setup
Vue.use(DashboardPlugin);

const messages = messageService.messages();

const i18n = new VueI18n({
  locale: 'en',
  messages,
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  i18n,
  router,
  store,
});
