<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar background-color="primary">
      <template slot="links">
        <sidebar-item :link="{
          name: 'Dashboard',
          icon: 'ni ni-shop text-primary',
          path: '/dashboard'
        }">
        </sidebar-item>
        <sidebar-item :link="{
          name: 'Channels',
          icon: 'ni ni-send text-blue'
        }">
          <sidebar-item :link="{ name: 'Push Notifications', path: '/channel/push-notifications' }" />
          <sidebar-item :link="{ name: 'In App Notifications', path: '/channel/in-app-notifications' }" />
        </sidebar-item>
        <sidebar-item :link="{
          name: 'Audience',
          icon: 'ni ni-single-02 text-orange'
        }">
          <sidebar-item :link="{ name: 'Segments', path: '/audience/segments' }" />
          <sidebar-item :link="{ name: 'Subscribers', path: '/audience/subscribers' }" />
          <sidebar-item :link="{ name: 'TestUsers', path: '/audience/test-users' }" />
        </sidebar-item>
        <sidebar-item :link="{
          name: 'Settings',
          icon: 'ni ni-settings text-secondary',
        }">
          <sidebar-item :link="{ name: 'API Keys', path: '/settings/api-keys' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Applications', path: '/applications' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Accounts', path: '/accounts' }"></sidebar-item>
          <sidebar-item :link="{ name: 'Billing', path: '/billing' }"></sidebar-item>
        </sidebar-item>
      </template>

      <template slot="links-after">
        <hr class="my-3">
        <h6 class="navbar-heading p-0 text-muted">Documentation</h6>
        <b-nav class="navbar-nav mb-md-3">
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/quick-start/argon-dashboard">
            <i class="ni ni-spaceship"></i>
            <b-nav-text class="p-0">Getting started</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/colors/argon-dashboard">
            <i class="ni ni-palette"></i>
            <b-nav-text class="p-0">Foundation</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/avatar/argon-dashboard">
            <i class="ni ni-ui-04"></i>
            <b-nav-text class="p-0">Components</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/charts/argon-dashboard">
            <i class="ni ni-chart-pie-35"></i>
            <b-nav-text class="p-0">Plugins</b-nav-text>
          </b-nav-item>
        </b-nav>
      </template>
    </side-bar>

    <div class="main-content">
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import { mapActions } from 'vuex';
import { FadeTransition } from 'vue2-transitions';
import ContentFooter from './ContentFooter.vue';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    ContentFooter,
    FadeTransition,
  },
  methods: {
    ...mapActions(['initialize', 'afterLogin']),
    initScrollbar() {
      const isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
  async created() {
    if (localStorage.xennAuthToken) {
      this.initialize();
      this.afterLogin();
    }
  },
};
</script>
