import { xenn } from './index';

export default {
  get(params) {
    return xenn.get('/push-campaigns', { params: params });
  },
  getById(id) {
    return xenn.get(`/push-campaigns/${id}`);
  },
  getSettings() {
    return xenn.get('/campaign-settings');
  },
  getCumulativeStatistics(params) {
    return xenn.get('/push-campaign-statistics/cumulative-statistics', { params: params });
  },
  getDailyCumulativeStatistics(params) {
    return xenn.get('/push-campaign-statistics/daily-cumulative-statistics', { params: params });
  },
  getStatisticsById(id, params) {
    return xenn.get(`/campaigns/${id}/campaign-statistics`, { params: params });
  },
  getStatistics(params) {
    return xenn.get('/campaign-statistics', { params: params });
  },
  getPushTestTokens() {
    return xenn.get('/push-test-member/tokens');
  },
  update(id, params) {
    return xenn.put(`/push-campaigns/${id}`, params);
  },
  create(params) {
    return xenn.post('/push-campaigns', params);
  },
  approve(id) {
    return xenn.post(`/push-campaigns/${id}/approve`);
  },
  delete(id) {
    return xenn.delete(`/push-campaigns/${id}`);
  },
  cancel(id) {
    return xenn.post(`/push-campaigns/${id}/cancel`);
  },
  copy(id) {
    return xenn.post(`/push-campaigns/${id}/copy`);
  },
  testMessage(message) {
    return xenn.post('/push-notification-send-to-test-users', {
      ...message,
    });
  },
};
