<template>
  <b-img class="application-icon" size="xs" v-b-tooltip.hover :title="title"
         :src="'/img/icons/' + svg + '.svg'"/>
</template>
<script>
export default {
  name: 'base-img-icon',
  props: {
    title: {
      type: String,
      required: false,
    },
    svg: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: false,
    },
  },
};
</script>
<style>
.application-icon {
  width: 20px;
  height: 20px;
}
</style>
