import axios from 'axios';
import qs from 'qs';

const common = {};
if (localStorage.xennAuthToken && localStorage.xennAuthToken.length) {
  common.Authorization = `Bearer ${localStorage.xennAuthToken}`;
}
const xenn = axios.create({
  baseURL: process.env.NODE_ENV === 'local' ? 'http://localhost:8080' : 'https://apigw.qulerts.com',
  headers: {
    common,
  },
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
});


const success = (res) => {
  if (res.headers.location) {
    return {
      location: res.headers.location,
      ...res.data,
    };
  }

  return res.data;
};

const error = (err) => {
  if (err.response.status === 401) {
    localStorage.removeItem('xennAuthToken');
    window.location.replace('/login');
  } else if (err.response.status === 503) {
    window.location.replace('/error-500');
  } else if (err.response.status === 404) {
    //window.location.replace('/error-404');
  } else if (err.response.status === 500) {
    return {
      status: err.response.status,
      message: {
        error: 'Internal Server Error',
        params: ['Internal Server Error'],
      },
      hasError: true,
    };
  } else if (err.response.status === 400) {
    return {
      status: err.response.status,
      message: {
        error: err.response.data
      },
      hasError: true,
    };
  }
  return {
    status: err.response.status,
    message: JSON.parse(err.response.data
      .replaceAll('\n', '')
      .replaceAll('"{"', '{"')
      .replaceAll('"}"', '"}')),
    hasError: true,
  };
};

xenn.interceptors.response.use(res => success(res), err => error(err));

export {
  xenn,
};
