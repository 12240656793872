import applicationService from '../services/applications';
import profileService from '../services/profile';
import pushnotificaitons from '../services/pushnotifications';
import organizationService from '../services/organization';

export default {
  initialize({ commit }) {
    applicationService.get()
      .then((apps) => {
        commit('setApplications', apps);
      });

    profileService.profile()
      .then((profile) => {
        commit('setProfile', profile);
        organizationService.customers(profile.customer.organizationId)
          .then((customers) => {
            commit('setOrganizationCustomers', customers);
          });
      });
  },
  updateAuthToken({ commit }, token) {
    commit('setAuthToken', token);
  },
  afterLogin({ commit }) {
    commit('setLoggedIn', true);
  },
  logout({ commit }) {
    commit('setAuthToken', '');
    commit('setLoggedIn', false);
    commit('setProfile', { user: {} });
  },
};
