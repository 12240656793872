import { xenn } from '../services/index';

export default {
  setAuthToken(state, token) {
    if (token) {
      state.authToken = token;
      localStorage.setItem('xennAuthToken', token);
      xenn.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      state.authToken = null;
      localStorage.removeItem('xennAuthToken');
      delete xenn.defaults.headers.common.Authorization;
    }
  },
  setApplications(state, data) {
    state.applications = data.results;
  },
  setProfile(state, data) {
    state.profile = data;
  },
  setLoggedIn(state, data) {
    state.loggedIn = data;
  },
  setOrganizationCustomers(state, data) {
    state.organizationCustomers = data;
  },
};
