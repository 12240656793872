import { xenn } from './index';

export default {
  switchOrganization(organizationId, customerId) {
    return xenn.post('/switch-account', { organizationId: organizationId, customerId: customerId });
  },
  customers(organizationId) {
    return xenn.get(`/organizations/customers`);
  },
};
