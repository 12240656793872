import { DateTime } from 'luxon';

const fromISO = date => DateTime.fromISO(date)
  .toISO();
const fromEpoch = date => DateTime.fromMillis(date);
const now = () => DateTime.utc()
  .toISO();
const today = () => DateTime
  .local()
  .set({
    hour: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
  .toISO();
const yesterday = () => DateTime
  .local()
  .minus({ days: 1 })
  .set({
    hour: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
  .toISO();
const yesterdayEOD = () => DateTime
  .local()
  .minus({
    days: 1,
  })
  .set({
    hour: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 999,
  })
  .toISO();
const daysBefore = (days, date = DateTime.utc()) => date
  .minus({ days: days })
  .toISO();
const daysAfter = (days, date = DateTime.utc()) => date
  .plus({ days: days })
  .toISO();
const diffNow = time => DateTime.fromISO(time)
  .diffNow('minutes')
  .toObject();
const formatISODate = (option, format) => (option ? DateTime.fromISO(option)
  .toFormat(format || 'dd MMM yyyy') : '-');

const formatISODateShort = (option, format) => (option ? DateTime.fromISO(option)
  .toFormat(format || 'dd MMM') : '-');
const formatDate = (option, format) => (option ? DateTime.fromJSDate(option).toFormat(format || 'dd MMM yyyy HH:mm') : '-');
const formatISODateTime = (option, format) => (option ? DateTime.fromISO(option)
  .toFormat(format || 'dd MMM yyyy HH:mm') : '-');

const toIsoDateTime = option => (DateTime.fromISO(option).toFormat("yyyy-MM-dd'T'HH:mm:ss"));
const formatPartDate = (option, format) => (option ? DateTime.fromISO(option)
  .toFormat(format || 'yyyyMMdd') : '-');
const formatEpochDateTime = (option, format) => (option ? DateTime.fromMillis(option)
  .toFormat(format || 'dd MMM yyyy HH:mm') : '-');
const formatTime = (option, format) => (option ? DateTime.fromISO(option)
  .toFormat(format || 'HH:mm') : '-');
const from = (date, format) => (DateTime.fromFormat(date, format || 'yyyyMMdd'));

const beforeNowTillStartSecond = time => DateTime.utc()
  .minus(time)
  .set({
    milliseconds: 0,
    seconds: 0,
  })
  .toISO();

const formatDatepicker = date => date.substring(0, 16);

export {
  fromISO,
  now,
  diffNow,
  formatISODate,
  beforeNowTillStartSecond,
  formatISODateTime,
  fromEpoch,
  formatEpochDateTime,
  formatTime,
  daysBefore,
  daysAfter,
  today,
  yesterday,
  yesterdayEOD,
  formatPartDate,
  from,
  formatDate,
  formatISODateShort,
  toIsoDateTime,
  formatDatepicker,
};
