import { xenn } from './index';

export default {
  list(params) {
    return xenn.get('/applications', { params: params });
  },
  get() {
    return xenn.get('/applications?size=100');
  },
  detail(id) {
    return xenn.get(`/applications/${id}`);
  },
  post(options) {
    return xenn.post('/applications', options);
  },
  put(id, options) {
    return xenn.put(`/applications/${id}`, options);
  },
  pushCredentials(id) {
    return xenn.get(`/applications/${id}/push-credentials`);
  },
  activate(applicationId, credentialId) {
    return xenn.post(`/applications/${applicationId}/push-credentials/${credentialId}/activate`);
  },
};
