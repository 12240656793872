var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',{attrs:{"background-color":"primary"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
        name: 'Dashboard',
        icon: 'ni ni-shop text-primary',
        path: '/dashboard'
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Channels',
        icon: 'ni ni-send text-blue'
      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Push Notifications', path: '/channel/push-notifications' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'In App Notifications', path: '/channel/in-app-notifications' }}})],1),_c('sidebar-item',{attrs:{"link":{
        name: 'Audience',
        icon: 'ni ni-single-02 text-orange'
      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Segments', path: '/audience/segments' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Subscribers', path: '/audience/subscribers' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'TestUsers', path: '/audience/test-users' }}})],1),_c('sidebar-item',{attrs:{"link":{
        name: 'Settings',
        icon: 'ni ni-settings text-secondary',
      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'API Keys', path: '/settings/api-keys' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Applications', path: '/applications' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Accounts', path: '/accounts' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Billing', path: '/billing' }}})],1)],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"}),_c('h6',{staticClass:"navbar-heading p-0 text-muted"},[_vm._v("Documentation")]),_c('b-nav',{staticClass:"navbar-nav mb-md-3"},[_c('b-nav-item',{attrs:{"href":"https://www.creative-tim.com/learning-lab/bootstrap-vue/quick-start/argon-dashboard"}},[_c('i',{staticClass:"ni ni-spaceship"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Getting started")])],1),_c('b-nav-item',{attrs:{"href":"https://www.creative-tim.com/learning-lab/bootstrap-vue/colors/argon-dashboard"}},[_c('i',{staticClass:"ni ni-palette"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Foundation")])],1),_c('b-nav-item',{attrs:{"href":"https://www.creative-tim.com/learning-lab/bootstrap-vue/avatar/argon-dashboard"}},[_c('i',{staticClass:"ni ni-ui-04"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Components")])],1),_c('b-nav-item',{attrs:{"href":"https://www.creative-tim.com/learning-lab/bootstrap-vue/charts/argon-dashboard"}},[_c('i',{staticClass:"ni ni-chart-pie-35"}),_c('b-nav-text',{staticClass:"p-0"},[_vm._v("Plugins")])],1)],1)],1)],2),_c('div',{staticClass:"main-content"},[_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }