export default {
  messages() {
    return {
      en: {
        common: {
          targetAudience: 'Target Audience',
          startDate: 'Start Date',
          expireDate: 'Expire Date',
          endDate: 'End Date',
          every: 'Every {every}',
          times: '{times} Time(s)',
          displayFrequency: 'Display Frequency',
          campaignName: 'Campaign Name',
          startSending: 'Start sending',
        },
        pushNotification: {
          timing: 'Timing',
          scheduleType: {
            immediately: 'Send Immediately',
            scheduled: 'Send Later',
          },
          includedSegments: 'Selected Segments',
          excludedSegments: 'Excluded Segments',
          title: 'Title',
          subTitle: 'Subtitle',
          body: 'Body',
          startSending: 'Deliver Starts At',
          estimatedReachSize: 'Estimated Reach Size',
          saveAsDraft: 'Save As Draft',
          sendNotification: 'Send Notification',
          save: 'Save',
          schedule: 'Schedule',
          audience: 'Audience',
          startSendingNotification: 'Start Sending Notification',
          modifyNotification: 'Modify Notification',
          confirmMessageDetails: 'Confirm Message Details',
        },
        inAppNotification: {
          frequency: {
            onlyOnce: 'Show Only Once',
            manyTimes: 'Show Many Times',
            periodic: 'Periodic',
          },
          audienceType: {
            allUsers: 'Target All Users',
            segment: 'Target Selected Segments',
          },
        },
      },
    };
  },
};
